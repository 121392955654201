import { ExpenceCategory, ExpenceCategoryInfo } from "../types";
import { Color } from "../colors";
import {
  billsIcon,
  eatoutIcon,
  groceryIcon,
  healthIcon,
  homerentIcon,
  travellingIcon,
} from "../images";

export const CATEGORIES: ExpenceCategoryInfo[] = [
  {
    type: ExpenceCategory.DINING,
    icon: eatoutIcon,
    name: "Dining",
    color: Color.EGGPLANT,
  },
  {
    type: ExpenceCategory.GROCERY,
    icon: groceryIcon,
    name: "Grocery",
    color: Color.ROSE,
  },
  {
    type: ExpenceCategory.SHOPPING,
    icon: healthIcon,
    name: "Shopping",
    color: Color.TREE,
  },
  {
    type: ExpenceCategory.TRAVEL,
    icon: travellingIcon,
    name: "Travel",
    color: Color.LEMON,
  },
  {
    type: ExpenceCategory.BILLS,
    icon: billsIcon,
    name: "Bills",
    color: Color.TOMATO,
  },
  {
    type: ExpenceCategory.HEALTH,
    icon: healthIcon,
    name: "Health",
    color: Color.SKY,
  },
  {
    type: ExpenceCategory.HOME,
    icon: homerentIcon,
    name: "Home",
    color: Color.ORANGE,
  },
];
