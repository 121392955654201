import { FC } from "react";
import { closeIcon } from "../lib/images";
import c from "./MonthSelection.module.scss";

const MonthSelction: FC<{ show: boolean; onClose: () => void }> = ({
  show,
  onClose,
}) => {
  if (!show) return null;

  return (
    <div className={c.root} style={{ display: show ? "block" : "none" }}>
      <div className={c.selector}>
        <div className={c.yearMonthBox}>
          <img
            alt=""
            src={closeIcon}
            onClick={onClose}
            style={{ cursor: "pointer" }}
          />
          <div>Year</div>
          <div className={c.active}>Month</div>
          <div>Week</div>
          <div>Day</div>
          <div>Calendar</div>
        </div>

        <hr />
        <h2>2021</h2>
        <div className={c.months}>
          <div>January</div>
          <div>February</div>
          <div>March</div>
          <div>April</div>
          <div>May</div>
          <div>June</div>
          <div>July</div>
          <div>August</div>
          <div>September</div>
          <div>October</div>
          <div>November</div>
          <div>December</div>
        </div>
      </div>
    </div>
  );
};

export default MonthSelction;
