import React, { ChangeEventHandler, useEffect, useState } from "react";
import { addExpense, getExpenses } from "../lib/db/demoData";

const Demo = () => {
  const [storedExpenses, setStoredExpenses] = useState<string[]>([]);
  const [expenses, setExpenses] = useState(["shoes", "watch" , "HELLO"]);

  const [val, setVal] = useState("");

  const onChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    setVal(event.target.value);
  }

  const fetchExpenses = () => {
    getExpenses().then((v: string[]) => setStoredExpenses(v));
  };
  useEffect(fetchExpenses, []);

  const generateExpense = () => {
    // const e = Math.random()
    //   .toString(36)
    //   .replace(/[^a-z]+/g, "")
    //   .substring(0, 5);
    setExpenses([...expenses, val]);
    setVal("");
  };

  const removeExpense = (e: string) => {
    const editedExpenses = expenses.filter((expense) => expense !== e);
    setExpenses(editedExpenses);
  };

  const storeExpense = (e: string) => {
    addExpense(e).then(() => {
      fetchExpenses();
      removeExpense(e);
    });
  };

  return (
    <header>
      <div>
        <h4>Click to add an expense</h4>
        <input type="text" className="gen" value={val} onChange={onChange}/>
        <button onClick={generateExpense}>Add expense</button>
        <div>
          {storedExpenses.map((e) => (
            <div style={{ fontWeight: 700 }}>{e}</div>
          ))}
          {expenses.map((e) => (
            <div>
              {e}
              <button onClick={() => storeExpense(e)}>store</button>
            </div>
          ))}
        </div>
      </div>
    </header>
  );
};

export default Demo;
