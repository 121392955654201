import React, { FC, MouseEventHandler, useState } from "react";
import { deleteExpense } from "../lib/db/expenses";
import { expandIcon, deleteIcon } from "../lib/images";
import { ExpenceCategoryInfo, Expense } from "../lib/types";
import classes from "./ExpenseCategorySection.module.scss";

type Props2 = {
  category: ExpenceCategoryInfo;
  categoryExpenses: Expense[];
  resetExpenses: () => void;
};

export const CategorySection: FC<Props2> = ({
  category,
  categoryExpenses,
  resetExpenses,
}) => {
  const [showDetails, setShowDetails] = useState(false);

  const expandexpenses: MouseEventHandler = (event) => {
    setShowDetails((current) => !current);
  };

  const total = categoryExpenses.reduce((t, e) => t + e.amount, 0);

  const deletee = (e: Expense) => {
    if (
      window.confirm("The transaction will be deleted, press OK to confirm")
    ) {
      deleteExpense(e).then(() => {
        // refresh the local data
        resetExpenses();
      });
    }
  };

  return (
    <div className={classes.root}>
      <div
        className={classes.li}
        style={{ cursor: "pointer" }}
        onClick={expandexpenses}
      >
        <img alt="" src={category.icon} />
        {category.name}
        <span className={classes.totalspan}>
          ₹ {total}
          <img alt="" src={expandIcon} />
        </span>
      </div>

      <div
        className={classes.expand}
        style={{ display: showDetails ? "block" : "none" }}
      >
        {categoryExpenses.map((e) => {
          return (
            <div className={classes.expandeddiv} key={e.id}>
              <div className={classes.namediv} key={e.id}>
                {e.name}
              </div>
              <div className={classes.deletespan}>
                {e.amount}
                <img
                  src={deleteIcon}
                  alt=""
                  className={classes.deleteIcon}
                  onClick={() => deletee(e)}
                />
              </div>{" "}
            </div>
          );
        })}
      </div>
    </div>
  );
};
