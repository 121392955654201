import { Color } from "./colors";

export type Expense = {
  id: string;
  category: ExpenceCategory;
  name: string;
  amount: number;
  paymentType: PaymentType;
  isDeleted?: boolean;
};

export enum ExpenceCategory {
  DINING = "dining",
  GROCERY = "grocery",
  SHOPPING = "shopping",
  TRAVEL = "travel",
  BILLS = "bills",
  HEALTH = "health",
  HOME = "home",
}

export enum PaymentType {
  CASH = "cash",
  CARD = "card",
  GPAY = "gpay",
  PHONEPE = "phonepe",
}

export type ExpenceCategoryInfo = {
  type: ExpenceCategory;
  icon: string;
  name: string;
  color: Color;
};
