import { openDB } from "idb";
import { Expense } from "../types";

const DB_LEDGER = "ledger";
const STORE_EXPENSES = "expenses";

const getDb = openDB(DB_LEDGER, 2, {
  upgrade: (db) => {
    db.createObjectStore(STORE_EXPENSES);
  },
});

export const addExpense = async (e: Expense) => {
  const db = await getDb;
  return db.add(STORE_EXPENSES, e, e.id);
};

export const getExpenses = async () => {
  const db = await getDb;
  const filterDeleted = (e: Expense[]) => e.filter(e => !e.isDeleted);
  return db.getAll(STORE_EXPENSES).then(filterDeleted);
};

export const deleteExpense = async (e: Expense) => {
  return updateExpense({...e, isDeleted: true});
};

export const updateExpense = async (e: Expense) => {
  const db = await getDb;
  console.log(e, 'updating');
  return db.put(STORE_EXPENSES, e, e.id);
};
