import React, { FC } from "react";
import { downIcon } from "../lib/images";
import c from "./ExpensesPage.module.scss";

type Props1 = {
  total: number;
  changemonth: any;
};

export const ExpensesHeader: FC<Props1> = ({ total, changemonth }) => {
  const Totalspent = total;

  return (
    <header className={c.header}>
      <div>
        <h4>Your Total Spending</h4>
        <h1>
          <strong>₹ {Totalspent}</strong>
        </h1>
        <div className={c.formonth} onClick={changemonth}>
          for December <img alt="" src={downIcon} />
        </div>
      </div>
    </header>
  );
};
