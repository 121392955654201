import { useCallback, useState } from "react";

// source: https://github.com/Toleckk/use-boolean-state/blob/master/src/index.tsx
export const useBooleanState = (
  initialState = false
): [boolean, () => void, () => void, () => void, (state: boolean) => void] => {
  const [state, setState] = useState(initialState);

  const setTrue = useCallback(() => setState(true), [setState]);
  const setFalse = useCallback(() => setState(false), [setState]);
  const toggle = useCallback(() => setState((state) => !state), [setState]);

  return [state, setTrue, setFalse, toggle, setState];
};
