export enum Color {
  EGGPLANT = "#7D8EE1",
  ROSE = "#FF606F",
  TREE = "#5BAFA5",
  LEMON = "#E2F56E",
  TOMATO = "#ff6347",
  SKY = "#6EDDF5",
  ORANGE = "#FF9C40",
}

export default Object.values(Color);
