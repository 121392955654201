import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Demo from "./Demo";
import ExpensesPage from "./ExpensesPage";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<ExpensesPage />} />
        <Route path="demo" element={<Demo />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
