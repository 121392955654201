import React, { useEffect, useState } from "react";
import "./App.css";
import { ExpensesHeader } from "./ExpensesHeader";
import { CategorySection } from "./ExpenseCategorySection";
import { CATEGORIES } from "../lib/constants";
import MonthSelction from "./MonthSelection";
import { useBooleanState } from "../lib/util";
import { addTransactionIcon } from "../lib/images";
import AddTransaction from "./TransactionAdderPage";
import c from "./ExpensesPage.module.scss";
import { getExpenses } from "../lib/db/expenses";
import { Expense } from "../lib/types";

function ExpensesPage() {
  const [showaddtranspage, setShowaddtranspage] = useState(false);
  const [expenses, setEXPENSES] = useState<Expense[]>([]);

  const resetExpenses = () => {
    getExpenses().then((e) => {
      setEXPENSES(e);
    });
  };

  const fetchExpenses = () => {
    getExpenses().then((v: Expense[]) => setEXPENSES(v));
  };
  useEffect(fetchExpenses, []);

  const togglePage = () => {
    setShowaddtranspage((c) => !c);
    resetExpenses();
  };

  const [showMonthPicker, setshowMonthPicker, resetshowMonthPicker] =
    useBooleanState();

  const totalAmount = expenses.reduce((tot, exp) => tot + exp.amount, 0);

  return (
    <div>
      <div className={c.expenses}>
        <ExpensesHeader total={totalAmount} changemonth={setshowMonthPicker} />
        <div className={c.yourexp}>
          Your Expenses <span> All Transactions </span>
        </div>
        <hr></hr>
        <div>
          {CATEGORIES.map((c) => {
            const categoryExpenses = expenses.filter(
              (e) => e.category === c.type
            );

            return (
              <CategorySection
                key={c.type}
                category={c}
                categoryExpenses={categoryExpenses}
                resetExpenses={resetExpenses}
              />
            );
          })}
        </div>
      </div>
      <MonthSelction show={showMonthPicker} onClose={resetshowMonthPicker} />
      <AddTransaction
        showaddtranspage={showaddtranspage}
        togglePage={togglePage}
      />
      <img
        className={c.addbutton}
        src={addTransactionIcon}
        alt=""
        onClick={togglePage}
      />
    </div>
  );
}

export default ExpensesPage;
