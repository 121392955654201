import { openDB } from "idb";

const DB_LEDGER = "ledger_demo";
const STORE_EXPENSES = "expenses";

const getDb = openDB(DB_LEDGER, 1, {
  upgrade: (db) => {
    db.createObjectStore(STORE_EXPENSES);
  },
});

export const addExpense = async (e: string) => {
  const db = await getDb;
  return db.add(STORE_EXPENSES, e, e);
};

export const getExpenses = async () => {
  const db = await getDb;
  return db.getAll(STORE_EXPENSES);
};

export const deleteExpense = async (e: string) => {
  const db = await getDb;
  return db.delete(STORE_EXPENSES, e);
};
