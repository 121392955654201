import { FC, useState, ChangeEventHandler } from "react";
import { addExpense } from "../lib/db/expenses";
import {v1 as uuid} from "uuid";
import {
  attachmentIcon,
  closeIcon,
  leftarrowIcon,
  saveTransIcon,
} from "../lib/images";
import { ExpenceCategory, Expense, PaymentType } from "../lib/types";
import classes from "./TransactionAdderPage.module.scss";

type Props = {
  showaddtranspage: boolean;
  togglePage: () => void;
};

const AddTransaction: FC<Props> = ({ showaddtranspage = false, togglePage }) => {

  const [amount, setAmount] = useState(0);
  const [name, setName] = useState("");
  const [category, setCategory] = useState(ExpenceCategory.BILLS);
  const [paymentType, setPaymentType] = useState(PaymentType.CASH);

  const onChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    setName(event.target.value);
  }
  const onChangeAmount: ChangeEventHandler<HTMLInputElement> = (event) => {
    setAmount(event.target.valueAsNumber);
  }
  const onChangeCategory: ChangeEventHandler<HTMLSelectElement> = (event) => {
    setCategory(event.target.value as ExpenceCategory);
  }
  const onChangePaymentType = (paymentType: PaymentType, isChecked:boolean) => {
    if(isChecked)
      setPaymentType(paymentType);
  }

  const resetPage = () => {
    setAmount(0);
    setName("");
    setCategory(ExpenceCategory.BILLS);
    setPaymentType(PaymentType.CASH);
  }

  const saveTransaction = () => {
    const e: Expense = {
      id: uuid(),
      category,
      name,
      amount,
      paymentType,
    };
    addExpense(e);
    togglePage();
    resetPage();
  }

  return (
    <>
      <div
        className={classes.addtransactionpage}
        style={{ display: showaddtranspage ? "block" : "none" }}
      >
        <div className={classes.addtransadiv}>
          <div className={classes.headerAddtranspage}>
            <img src={leftarrowIcon} alt="" className={classes.leftarrowIcon} />{" "}
            <span>Add Your Expense</span>{" "}
            <img
              src={closeIcon}
              alt=""
              className={classes.closeIcon}
              onClick={togglePage}
            />
            <hr className={classes.hrAddtranspage} />
          </div>
          <div className={classes.bodyAddtranspage}>
            <div className={classes.enteramountBox}>
              <div>Enter amount</div>
              <span>₹</span>
              <input type="number" placeholder="0.00" value={amount || ""} onChange={onChangeAmount}/>
            </div>
            <div>Expense type</div>
            <span className={classes.toggleExpensetype}>
              <label className={classes.expensetypes}>
                <input
                  type="radio"
                  name="expensetype"
                  className={classes.et + " " + classes.etIncome}
                  value="Income"
                  disabled
                />
                <span>Income</span>
              </label>
              <label className={classes.expensetypes}>
                <input
                  type="radio"
                  name="expensetype"
                  className={classes.et + " " + classes.etExpense}
                  value="Expense"
                  defaultChecked
                  readOnly
                />
                <span>Expense</span>
              </label>
              <label className={classes.expensetypes}>
                <input
                  type="radio"
                  name="expensetype"
                  className={classes.et + " " + classes.etTransfer}
                  value="Transfer"
                  disabled
                />
                <span>Transfer</span>
              </label>
            </span>
            <div className={classes.categorydiv}>
              <div>Category</div>
              <select name="transCategory" id="" value={category} onChange={onChangeCategory}>
                <option value="dining">Food</option>
                <option value="shopping">Shopping</option>
                <option value="travel">travel</option>
                <option value="health">health</option>
                <option value="home">home</option>
                <option value="grocery">grocery</option>
                <option value="bills">Bills</option>
              </select>
            </div>
            <div className={classes.addnotediv}>
              <div>Note</div>
              <input
                type="text"
                name="Add a note"
                id=""
                placeholder="add a note" value={name} onChange={onChange}
              />{" "}
              <img src={attachmentIcon} alt="" />
            </div>
            
            <div className={classes.pmDiv}>
              <div>Payment Mode</div>
              <div className={classes.togglePaymentmode}>
                <label className={classes.paymentmodes}>
                  <input
                    type="radio"
                    name="paymentMode"
                    className={`${classes.pm} ${classes.pmCash}`}
                    onChange={(e) => onChangePaymentType(PaymentType.CASH, e.target.checked)}
                  />
                  <span>Cash</span>
                </label>
                <label className={classes.paymentmodes}>
                  <input
                    type="radio"
                    name="paymentMode"
                    className={`${classes.pm} ${classes.pmCash}`}
                    onChange={(e) => onChangePaymentType(PaymentType.CARD, e.target.checked)}
                  />
                  <span>Credit card</span>
                </label>
                <label className={classes.paymentmodes}>
                  <input
                    type="radio"
                    name="paymentMode"
                    className={`${classes.pm} ${classes.pmCash}`}
                    onChange={(e) => onChangePaymentType(PaymentType.GPAY, e.target.checked)}
                  />
                  <span>Gpay</span>
                </label>
                <label className={classes.paymentmodes}>
                  <input
                    type="radio"
                    name="paymentMode"
                    className={`${classes.pm} ${classes.pmCash}`}
                    onChange={(e) => onChangePaymentType(PaymentType.PHONEPE, e.target.checked)}
                  />
                  <span>Phonepe</span>
                </label>
              </div>
            </div>
            <div>Date & Time</div>
            <select name="transTime" id="">
              <option value="">today 6pm</option>
              <option value="">today 6pm</option>
              <option value="">today 6pm</option>
            </select>
            <div>
              <div>
                <div className={classes.savebutton}>
                  <img src={saveTransIcon} alt="" onClick={saveTransaction} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddTransaction;
